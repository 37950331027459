/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop
$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

Inside media queries 1em is always 16px i.e 1em = 16px at every screen size only inside media queries
*/
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 59%;
  }
}
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 56%;
  }
}
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 65%;
  }
}

body {
  box-sizing: border-box;
  position: relative;
  line-height: 1.5;
  font-family: sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: "Source Sans Pro", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

input:focus,
button:focus,
a:focus,
textarea:focus {
  outline: none;
}

button {
  border: none;
  cursor: pointer;
}

textarea {
  resize: none;
}

.heading-primary {
  font-size: 6rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  font-weight: bold;
}
@media only screen and (max-width: 37.5em) {
  .heading-primary {
    font-size: 2.5rem;
    text-align: left;
  }
}

.heading-sec__mb-bg {
  margin-bottom: 11rem;
}
@media only screen and (max-width: 56.25em) {
  .heading-sec__mb-bg {
    margin-bottom: 8rem;
  }
}

.heading-sec__mb-med {
  margin-bottom: 9rem;
}
@media only screen and (max-width: 56.25em) {
  .heading-sec__mb-med {
    margin-bottom: 8rem;
  }
}

.heading-sec__main {
  display: block;
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 3.5rem;
  position: relative;
}
.heading-sec__main--lt {
  color: #fff;
}
.heading-sec__main--lt::after {
  content: "";
  background: #fff !important;
}
.heading-sec__main::after {
  content: "";
  position: absolute;
  top: calc(100% + 1.5rem);
  height: 5px;
  width: 3rem;
  background: #0062b9;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}
@media only screen and (max-width: 37.5em) {
  .heading-sec__main::after {
    top: calc(100% + 1.2rem);
  }
}

.heading-sec__sub {
  display: block;
  text-align: center;
  color: #777;
  font-size: 2rem;
  font-weight: 500;
  max-width: 80rem;
  margin: auto;
  line-height: 1.6;
}
@media only screen and (max-width: 37.5em) {
  .heading-sec__sub {
    font-size: 1.8rem;
    padding: 0 1rem;
  }
}
.heading-sec__sub--lt {
  color: #eee;
}

.heading-sm {
  font-size: 2.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.main-container {
  max-width: 120rem;
  margin: auto;
  width: 92%;
}

.btn {
  background: #fff;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
  font-weight: 700;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
}
.btn:hover {
  transform: translateY(-3px);
}
.btn--bg {
  padding: 1.5rem 8rem;
  font-size: 2rem;
}
.btn--med {
  padding: 1.5rem 5rem;
  font-size: 1.6rem;
}

.btn--theme {
  background: #0062b9;
  color: #fff;
}
.btn--theme-inv {
  color: #0062b9;
  background: #fff;
  border: 2px solid #0062b9;
  box-shadow: none;
  padding: calc(1.5rem - 2px) calc(5rem - 2px);
}
@media only screen and (max-width: 37.5em) {
  .btn--med {
    padding: 1.5rem 4rem;
    font-size: 1.6rem;
  }
  .btn-contact {
    text-align: center;
  }
}

.btn-11 {
  cursor: pointer;
}
.sec-pad {
  padding: 12rem 0;
}
@media only screen and (max-width: 56.25em) {
  .sec-pad {
    padding: 8rem 0;
  }
}

.text-primary {
  color: #fff;
  font-size: 2.2rem;
  text-align: center;
  width: 100%;
  line-height: 1.6;
}
@media only screen and (max-width: 37.5em) {
  .text-primary {
    text-align: start;
    font-size: 2rem;
  }
}

.d-none {
  display: none;
}

.projects__row {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 5rem;
  margin-bottom: 11rem;
}
@media only screen and (max-width: 56.25em) {
  .projects__row {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    margin-bottom: 8rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .projects__row {
    text-align: center;
  }
}
.projects__row:last-child {
  margin-bottom: 0;
}
.projects__row-img-cont {
  overflow: hidden;
}
.projects__row-img {
  width: 100%;
  display: block;
  object-fit: cover;
}
.projects__row-content {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (max-width: 56.25em) {
  .projects__row-content {
    align-items: center;
  }
}
.projects__row-content-title {
  font-weight: 700;
  font-size: 2.8rem;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .projects__row-content-title {
    font-size: 2.4rem;
  }
}
.projects__row-content-desc {
  font-size: 1.8rem;
  color: grey;
  max-width: 60rem;
  line-height: 1.7;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .projects__row-content-desc {
    font-size: 1.7rem;
  }
}

.contact {
  background: linear-gradient(
      to right,
      rgba(0, 98, 185, 0.8),
      rgba(0, 98, 185, 0.8)
    ),
    url(../src/assets/svg/common-bg.svg);
  background-size: cover;
  background-position: center;
}
.contact__form-container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: white;
  padding: 4rem;
  margin-top: 6rem;
  max-width: 80rem;
  text-align: right;
  width: 95%;
  border-radius: 5px;
  margin: 5rem auto 0 auto;
}
@media only screen and (max-width: 37.5em) {
  .contact__form-container {
    padding: 3rem;
  }
}
.contact__form-field {
  margin-bottom: 4rem;
}
@media only screen and (max-width: 37.5em) {
  .contact__form-field {
    margin-bottom: 3rem;
  }
}
.contact__form-label {
  color: #666;
  font-size: 1.4rem;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 1rem;
  display: block;
  text-align: left;
}
.contact__form-input {
  color: #333;
  padding: 2rem;
  width: 100%;
  border: 1px solid #ebebeb;
  font-size: 1.6rem;
  letter-spacing: 0px;
  background: #f0f0f0;
  border-radius: 5px;
  font-weight: 600;
  /* Code for Modern Browsers */
  /* Code for WebKit, Blink, Edge */
  /* Code for Internet Explorer 10-11 */
  /* Code for Microsoft Edge */
  /* Code for Mozilla Firefox 4 to 18 */
  /* Code for Mozilla Firefox 19+ */
}
.contact__form-input::placeholder {
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input::-webkit-input-placeholder {
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input:-ms-input-placeholder {
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input::-ms-input-placeholder {
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input:-moz-placeholder {
  opacity: 1;
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input::-moz-placeholder {
  opacity: 1;
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__btn {
  width: 30%;
  padding: 2rem 4rem;
  font-size: 1.6rem;
}
@media only screen and (max-width: 37.5em) {
  .contact__btn {
    width: 100%;
  }
}

.project-cs-hero {
  color: #fff;
  background: linear-gradient(
      to right,
      rgba(0, 98, 185, 0.8),
      rgba(0, 98, 185, 0.8)
    ),
    url(../src/assets/svg/common-bg.svg);
  background-size: cover;
  background-position: center;
  position: relative;
}
@media only screen and (max-width: 37.5em) {
  .project-cs-hero {
    height: unset;
    min-height: unset;
  }
}
.project-cs-hero__content {
  padding: 25rem 0 17rem 0;
  max-width: 90rem;
  width: 92%;
  margin: auto;
}
@media only screen and (max-width: 37.5em) {
  .project-cs-hero__content {
    padding: 19rem 0 13rem 0;
    margin: auto;
    position: static;
    transform: translate(0, 0);
  }
}
.project-cs-hero__info {
  margin: 3rem auto 0 auto;
  max-width: 80rem;
}
.project-cs-hero__cta {
  margin-top: 5rem;
  text-align: center;
}

.project-details__content {
  padding: 8rem 0;
  max-width: 90rem;
  margin: auto;
}
.project-details__content-title {
  font-weight: 700;
  font-size: 2.8rem;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .project-details__content-title {
    font-size: 2.4rem;
  }
}

.project-details__showcase-img-cont {
  width: 100%;
  margin-bottom: 6rem;
}

.project-details__showcase-img {
  width: 100%;
}

.project-details__content-main {
  width: 100%;
  max-width: 70rem;
  margin: auto;
}

.project-details__desc {
  margin: 0 0 7rem 0;
}
.project-details__desc-para {
  font-size: 1.8rem;
  line-height: 1.7;
  color: grey;
  text-align: justify;
  margin-bottom: 2rem;
}

.project-details__tools-used {
  margin: 0 0 7rem 0;
}
.project-details__tools-used-list {
  display: flex;
  flex-wrap: wrap;
}
.project-details__tools-used-item {
  padding: 1rem 2rem;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  font-size: 1.6rem;
  background: rgba(153, 153, 153, 0.2);
  border-radius: 5px;
  font-weight: 600;
  color: #777;
}

.project-details__links {
  margin: 0 0;
}
.project-details__links-btn {
  margin-right: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .project-details__links-btn {
    margin-right: 0;
    width: 70%;
    margin-bottom: 2rem;
    text-align: center;
  }
}
.project-details__links-btn:last-child {
  margin: 0;
}
@media only screen and (max-width: 37.5em) {
  .project-details__links-btn:last-child {
    margin: 0;
  }
}

/* this code is for to mouse*/
.mouse {
  width: 25px;
  height: 40px;
  border: 2px solid #eee;
  border-radius: 60px;
  position: relative;
  overflow: hidden;
}
.mouse::before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #eee;
  border-radius: 50%;
  opacity: 1;
  animation: wheel 1.3s infinite;
  -webkit-animation: wheel 1.3s infinite;
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 27px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 27px;
  }
}
