.home-hero {
  color: #fff;
  background: linear-gradient(
      to right,
      rgba(0, 98, 185, 0.8),
      rgba(0, 98, 185, 0.8)
    ),
    url(../assets/svg/common-bg.svg);
  background-position: center;
  height: 100vh;
  min-height: 80rem;
  max-height: 120rem;
  position: relative;
}

.home-hero__content {
  display: flex;
  justify-content: center;
  max-width: 120rem;
  margin: auto;
  width: 92%;
}

.home-hero__content1 {
  padding-top: 18%;
  padding-right: 5rem;
  max-width: 60%;
}

.home-hero__content2 {
  padding-top: 15%;
  max-width: 40%;
}

.home-hero__content2 img {
  display: flex;
  justify-content: center;
  position: inherit;
  top: 0;
  width: 310px;
}

@media only screen and (max-width: 56.25em) {
  .home-hero__content2 img {
    display: flex;
    width: 400px;
  }
}
.home-hero__socials {
  position: absolute;
  top: 50%;
  border: 2px solid #eee;
  border-left: 2px solid #eee;
  transform: translateY(-50%);
}

.home-hero__mouse-scroll-cont {
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
}

.home-hero__social {
  width: 5rem;
}
.home-hero__social-icon-link {
  width: 100%;
  display: block;
  padding: 1.2rem;
  border-bottom: 2px solid #eee;
  transition: background 0.3s;
}
.home-hero__social-icon-link:hover {
  background: rgba(255, 255, 255, 0.1);
}
.home-hero__social-icon-link--bd-none {
  border-bottom: 0;
}
.home-hero__social-icon {
  width: 100%;
}

.home-hero__info {
  margin: 3rem auto 0 auto;
  max-width: 80rem;
}
.home-hero__cta {
  margin-top: 5rem;
  text-align: center;
}

@media only screen and (max-width: 56.25em) and (min-width: 37.5em) {
  .home-hero__socials {
    display: none;
  }

  .heading-primary {
    max-width: 90%;
    text-align: left;
    font-size: 4rem;
  }

  .home-hero__info {
    margin: 1rem auto 0 auto;

    text-align: left;
  }
  .text-primary {
    text-align: justify;
    max-width: 60%;
  }

  .home-hero__cta {
    margin-top: 3rem;
    text-align: left;
    text-align: justify;
  }

  .home-hero__content1 {
    padding-top: 20%;
    padding-right: 0rem;
    max-width: 90%;
  }

  .home-hero__content2 {
    position: absolute;
    max-width: 100%;

    bottom: 0;
    right: 0;
  }

  .home-hero__content {
    padding: 1rem 0 13rem 0;
    margin: auto;
    position: static;
    transform: translate(0, 0);
    display: block;
    justify-content: flex-start;
    max-width: 100%;
    height: 100%;
  }
  .home-hero__mouse-scroll-cont {
    display: none;
  }

  .btn--bg {
    padding: 1rem 4rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .home-hero__mouse-scroll-cont {
    display: none;
  }

  .home-hero {
    height: 100vh;
  }
  .home-hero__content {
    padding: 3rem 0 13rem 0;
    margin: auto;
    position: static;
    transform: translate(0, 0);
    display: block;
    justify-content: flex-start;
    max-width: 100%;
    height: 100%;
  }

  .heading-primary {
    max-width: 100%;
  }

  .home-hero__cta {
    margin-top: 3rem;
    text-align: left;
    text-align: justify;
  }

  .home-hero__content1 {
    max-width: 95%;
  }
  .home-hero__content2 {
    position: absolute;
    max-width: 100%;
    z-index: -10;
    bottom: 0;
    right: 0;
  }

  .btn--bg {
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
  }

  .home-hero__content2 img {
    width: 290px;
    display: flex;
    z-index: -10;
  }

  .home-hero__info {
    margin: 1rem auto 0 auto;
    max-width: 80rem;
    text-align: justify;
  }
  .text-primary {
    text-align: start;
    max-width: 90%;
  }
}
