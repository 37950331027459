/* EXPERIENCE */

.experiences__row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 4rem;
  margin-bottom: 7rem;
}
@media only screen and (max-width: 37.5em) {
  .experiences__row {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin-bottom: 8rem;
    text-align: center;
  }
}
@media only screen and (max-width: 56.25em) {
  .experiences__row {
    grid-gap: 1rem;
  }
}
.experiences__row:last-child {
  margin-bottom: 0;
}
.experiences__row-img-cont {
  overflow: hidden;
  /* width: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 37.5em) {
  .experiences__row-img-cont {
    overflow: hidden;
    width: 70%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.experiences__row-img {
  width: 100%;
  display: block;
  object-fit: cover;
}
.experiences__row-content {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (max-width: 37.5em) {
  .experiences__row-content {
    align-items: center;
  }
}
.experiences__row-content-title {
  font-weight: 700;
  font-size: 2.8rem;
  /* margin-bottom: 0.1rem; */
}
.experiences__row-content-subtitle {
  color: #666;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .experiences__row-content-title {
    font-size: 2.4rem;
  }
}
.experiences__row-content-desc {
  padding-right: 1rem;
  font-size: 1.8rem;
  color: grey;
  max-width: 95%;
  text-align: justify;
  line-height: 1.7;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .experiences__row-content-desc {
    font-size: 1.7rem;
    padding-right: 0;
    max-width: 100%;
  }
}
