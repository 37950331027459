.about {
  background: #fafafa;
}
.about__content {
  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-gap: 5rem;
}
@media only screen and (max-width: 56.25em) {
  .about__content {
    grid-template-columns: 1fr;
    grid-gap: 8rem;
    text-align: center;
  }
  .about__content-details-para {
    padding: 0 1rem;
    text-align: justify;
  }
}

.about__content-title {
  font-weight: 700;
  font-size: 2.8rem;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .about__content-title {
    font-size: 2.4rem;
    padding: 0 1rem;
    text-align: center;
  }

  .about__content-details {
    text-align: center;
  }
}
.about__content-details-para {
  font-size: 1.8rem;
  color: grey;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.7;
  margin-bottom: 1rem;
  text-align: justify;
}
@media only screen and (max-width: 37.5em) {
  .about__content-details-para {
    padding: 0 1rem;
    text-align: justify;
  }
}
.about__content-details-para--hl {
  font-weight: 700;
  margin: 0 3px;
}
.about__content-details-para:last-child {
  margin-bottom: 3rem;
}
.skills {
  display: flex;
  flex-wrap: wrap;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 56.25em) {
  .skills {
    align-items: flex-end;
    justify-content: center;
  }
}
.skills__skill {
  padding: 1rem 2rem;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  font-size: 1.6rem;
  background: rgba(153, 153, 153, 0.2);
  border-radius: 5px;
  font-weight: 600;
  color: #777;
}
